import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import { Lock } from 'react-feather';
import { MdxArticle } from '../../types/Article';
import Layout, { Wrapper } from '../../layouts/Layout';
import { Typography } from '@material-ui/core';
import styled from '../../styled';
import { SEO } from '../../components/SEO';
import { HidableBranding as Branding } from '../../components/Branding';
import { Tags } from '../../components/Tags';
import { ENABLE_PAID_BLOG_POSTS } from '../../constants';
import { ProductHuntBanner } from '../../components/ProductHuntBanner';
import { Subheading } from '../../components/Subheading';

type PageQueryData = {
  allMdx: {
    edges: {
      node: MdxArticle;
    }[];
  };
};

const PaidDisplayDate = styled('time')`
  color: #ff8cb3;
  opacity: 0.5;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  font-size: 16px;

  svg {
    margin-left: 8px;
  }
`;

const DisplayDate = styled('time')`
  color: #767f89;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
`;

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  grid-row-gap: ${(p) => p.theme.spacing(1)}px;

  & > div {
    margin-bottom: 12px;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const COLOR = '#69c0ff';

const FeaturedDisplayDate = styled('time')`
  color: ${COLOR};
  opacity: 0.5;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  font-size: 16px;

  svg {
    margin-left: 8px;
  }
`;

const FeaturedStyledLink = styled(Link)`
  ${({ theme }) => css`
    color: #ff8cb3;
    font-size: ${theme.typography.h5.fontSize};
    font-weight: 700;

    @media (max-width: 800px) {
      font-size: ${theme.typography.h6.fontSize};
    }
  `};
`;

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    color: ${COLOR};
    font-size: ${theme.typography.h5.fontSize};
    font-weight: 700;

    @media (max-width: 800px) {
      font-size: ${theme.typography.h6.fontSize};
    }
  `};
`;

const PostContainer = styled('div')`
  ${({ theme }) => css`
    padding: ${theme.spacing(2)}px;
    margin: ${theme.spacing(4)}px auto;

    @media (max-width: 800px) {
      padding: ${theme.spacing(1)}px;
      margin: ${theme.spacing(2)}px auto;
    }
  `};
`;

const PaperInner = styled('div')`
  padding: ${(p) => p.theme.spacing(2)}px ${(p) => p.theme.spacing(4)}px 0;
  text-align: left;
`;

const FeaturedPostContainer = styled('div')`
  background-color: #432331;
  border-radius: 8px;
  padding-bottom: ${(p) => p.theme.spacing(4)}px;
`;

const PaidPostContainer = styled('div')`
  background-color: #1b3243;
  border-radius: 8px;
  padding-bottom: ${(p) => p.theme.spacing(4)}px;
`;

const PostSummary = styled('p')`
  ${({ theme }) => css`
    color: #e3e9f0;
    font-size: ${theme.typography.h6.fontSize};
    line-height: ${theme.typography.h6.lineHeight};

    @media (max-width: 800px) {
      font-size: ${theme.typography.body1.fontSize};
      line-height: ${theme.typography.body1.lineHeight};
    }
  `};
`;

const FeaturedPostLink = ({ post }: { post: MdxArticle }) => (
  <div style={{ height: '100%', position: 'relative' }}>
    <PaperInner>
      <h2>
        <StyledLink to={post.fields.slug}>
          {post.frontmatter.title}&nbsp;→
        </StyledLink>
      </h2>
      <FeaturedDisplayDate>{post.frontmatter.date}</FeaturedDisplayDate>
    </PaperInner>
  </div>
);

const PaidPostLink = ({ post }: { post: MdxArticle }) => (
  <div style={{ height: '100%', position: 'relative' }}>
    <PaperInner>
      <h2>
        <FeaturedStyledLink to={post.fields.slug}>
          {post.frontmatter.title}&nbsp;→
        </FeaturedStyledLink>
      </h2>
      <PaidDisplayDate>
        {post.frontmatter.date}
        <Lock size={18} />
      </PaidDisplayDate>
    </PaperInner>
  </div>
);

const PostLink = ({ post }: { post: MdxArticle }) => (
  <div style={{ height: '100%', position: 'relative' }}>
    <PaperInner>
      <h2>
        <StyledLink to={post.fields.slug}>{post.frontmatter.title}</StyledLink>
      </h2>
      <DisplayDate>{post.frontmatter.date}</DisplayDate>
      <PostSummary>{post.frontmatter.summary}</PostSummary>
      <Tags tags={post.frontmatter.tags} />
    </PaperInner>
  </div>
);

const IndexPage = ({
  data: {
    allMdx: { edges }
  }
}: {
  data: PageQueryData;
}) => {
  const posts = edges.filter((edge) => {
    if (!ENABLE_PAID_BLOG_POSTS) {
      return edge.node.frontmatter.tags.indexOf('paid') === -1;
    }
    return true;
  });

  const PaidPosts = posts
    .filter((edge) => edge.node.frontmatter.tags.indexOf('paid') !== -1)
    .map((edge) => (
      <div key={edge.node.fields.slug}>
        <PaidPostLink post={edge.node} />
      </div>
    ));

  const FeaturedPosts = posts
    .filter((edge) => edge.node.frontmatter.tags.indexOf('featured') !== -1)
    .map((edge) => (
      <div key={edge.node.fields.slug}>
        <FeaturedPostLink post={edge.node} />
      </div>
    ));

  const OtherPosts = posts.map((edge) => (
    <div key={edge.node.fields.slug}>
      <PostLink post={edge.node} />
    </div>
  ));

  return (
    <Layout>
      <ProductHuntBanner />
      <SEO
        title="Blog"
        siteUrl="https://bloggingfordevs.com"
        description="Get advice and resources for creating a strategy for your developer blog, and create content that reaches thousands without an existing audience."
        pathname="blog/"
      />
      <Wrapper style={{ maxWidth: '1200px' }}>
        <div
          style={{
            padding: '16px',
            margin: '0 auto',
            textAlign: 'center'
          }}
        >
          <Branding to="/">Blogging for Devs</Branding>
          <Typography variant="h4" component="h1" gutterBottom>
            Blog
          </Typography>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8, maxWidth: '550px', margin: '0 auto' }}
          >
            Wondering what you'll learn as a subscriber?
            <br />
            Most content is exclusive to the newsletter, but here's a small
            selection to give you an idea.
          </Typography>
        </div>
        <PostContainer>
          <Grid>
            <div>{OtherPosts}</div>
            <div>
              {ENABLE_PAID_BLOG_POSTS && (
                <>
                  <Subheading style={{ justifyContent: 'center' }}>
                    Latest Community Posts
                  </Subheading>
                  <br />
                  <FeaturedPostContainer>{PaidPosts}</FeaturedPostContainer>
                  <br />
                  <br />
                </>
              )}
              <Subheading style={{ justifyContent: 'center' }}>
                Popular Posts
              </Subheading>
              <br />
              <PaidPostContainer>{FeaturedPosts}</PaidPostContainer>
            </div>
          </Grid>
        </PostContainer>
      </Wrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            summary
            image
            tags
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default IndexPage;
